@import "../template/mixin_templates";
@import url('https://fonts.googleapis.com/css?family=Dosis:400,700|Poppins:400,700');

/* Variables */
$largeur_fenetre_grand: 1200px;
$padding_produits: 5px;

/*$small-screen: 640px;
$medium-screen: 1024px;
$large-screen: 1200px;
$xlarge-screen: 1440px;*/

/*-----------------------------
    KEYFRAME
-------------------------------*/

@keyframes sliderP {
  0% {
    opacity: 0;
    bottom: 110px;
  }
  100% {
    opacity: 1;
    bottom: 80px;
  }
}

@keyframes addCommentaires {
  0% {
    opacity: 0;
    transform: rotate(90deg);
  }
  20% {
    opacity: 0;
    transform: rotate(90deg);
  }
  100% {
    opacity: 1;
    transform: rotate(0deg);
  }
}

/*-----------------------------
    GENERAL
-------------------------------*/

body, html {
  font-size: 16px;
  font-family: 'Poppins', 'Arial', sans-serif;
  margin: 0;
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

@include titreBaseSize();

a {
  text-decoration: none;

  img {
    border: none;
  }
}

img {
  max-width: 100%;
  height: auto !important;
}

.img-logo {
  max-width: none;
}

ul {
  padding: 0;
  list-style: none;
}

iframe {
  max-width: 100%;
}

table {
  position: relative;
  table-layout: fixed;
  max-width: 100% !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: 'Poppins', sans-serif;
  margin-bottom: .5rem;
  font-weight: 700;
  line-height: 1.1;
  color: inherit;
}

//Titres
h1, .h1 {
  font-size: 1.375rem;
}

h2, .h2 {
  font-size: 1.25rem;
}

h3, .h3, h4, .h4 {
  font-size: 1.125rem;
}

h5, .h5 {
  font-size: 1rem;
}

h6, .h6 {
  font-size: .9375rem;
}

//Formulaires
select {
  height: 30px;
  line-height: 30px;
  padding: 5px 10px;
}

.bouton, .button, input[type="submit"],
body .template_fiche_produit_3 #fiche-produit .wrap-description .side-tabs a,
body .banner_diaporama .bx-wrapper .bx-next, body .banner_diaporama .bx-wrapper .bx-prev {
  padding: 8px 20px;
  font-size: 14px;
  display: inline-block;
  text-transform: uppercase;
  font-family: 'Dosis', sans-serif;
  font-weight: bold;
  transition: background .2s linear, color .2s linear;
  border-width: 0;
}

/* Conteneur principal */
.wrapper-content {
  width: 100%;
  display: inline-block;
}

#main-conteneur {
  overflow: hidden;
}

#wrapper {
  min-height: 60vh;
}

.page {

}

#headercontent {
  padding: 20px;
}

.wrapper, #headercontent, #footer #pub {
  width: $largeur_fenetre_grand;
  margin: 0 auto;
  position: relative;
}

.wrapper {
  padding: 0 20px 20px;
}


#footer #pub {
  padding: 0 20px 20px;

  &:empty {
    display: none;
  }
}

.content_p {
  position: relative;

  ul {
    padding-left: 15px;
    list-style-type: disc;
    list-style-position: inside;
  }
}

.side-content {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}


.title_section {
  text-align: center;
  font-size: 25px;
}

.nav-static-breadcrumb li:not(:last-child)::after {
  content: none;
}

#bloc-fil-title {
  //155px (hauteur header) - 40px (padding des conteneur)
  margin-top: 0;
  height: 150px;
  width: 100vw;
  margin-left: calc((100% - 100vw) / 2);
  padding: 35px 0;
  margin-bottom: 20px;
  position: relative;
  text-align: center;

  h1 {
    display: block !important;
  }

  nav.arianne {
    position: relative;
    z-index: 2;

    ul {
      text-align: center;
    }

    li {
      float: none;
      display: inline-block;
      font-size: 13px;
      font-family: 'Dosis', sans-serif;
      font-weight: 700;
      text-transform: uppercase;
    }

    a {


    }
  }

  .title_section {
    position: relative;
    z-index: 2;
  }

  .marque-center {
    display: inline-block;
    vertical-align: middle;
    padding: 0;
    width: auto;

    + h1 {
      display: inline-block !important;
      width: auto;
      vertical-align: middle;
      margin-left: 10px;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

aside.sidebar + section #bloc-fil-title {

}

.cmonsite-ficheproduit #bloc-fil-title {

}

.page > #bloc-fil-title {
  margin-top: -40px;
}

aside.sidebar {
  width: 20%;
  margin-top: 170px;
}

aside + section.content {
  width: 80%;

  #bloc-fil-title {
    margin-left: calc((100% - 100vw) / 2 - 12.5% - 11px);
  }
}

.s8.single-article {
  #bloc-fil-title {
    margin-left: calc((100% - 100vw) / 2 - 12.5% - 11px);
  }

  &.single-article-without-image + .blog-sidebar {
    margin-top: 170px;
  }
}

/* site en deux colonnes */
.sidebar, section.col-md-10 {
  float: left;
  padding: 20px;
}

section.col-md-10 {
  padding-right: 0;
  padding-top: 0;
}

.sidebar {
  width: 220px/$largeur_fenetre_grand * 100%;

  li a::before {
    content: '- ';
    margin: 0 5px;
    display: inline-block;
  }
}

section.col-md-10 {
  width: 100%-(220px/$largeur_fenetre_grand * 100%);
}

/* fil d'Arianne */
.nav-static-breadcrumb {
  font-size: 0;

  li {
    display: inline-block;
    font-size: 1rem;

    &:not(:first-child)::before {
      content: '/';
      display: inline-block;
      margin: 0 7px;
    }
  }
}

/*-----------------------------
    HEADER
-------------------------------*/

header {
  padding: 0;
  position: relative;
  text-align: left;
}

#headercontent {
  height: 110px;
  padding: 0;
}

.logo {
  display: inline-block;
  position: relative;
  top: 5px;

  img {
    max-height: 110px;
    height: 100px;
    max-width: 100%;
  }
}

.slogan_p {
  position: absolute;
  right: auto;
  width: auto;
  left: 260px;
  top: 37%;

  p {
    font-size: 1.5rem;
    text-align: left;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }
}

/*-----------------------------
    MENU
-------------------------------*/

.menu.menu-principal {
  &.fixed {
    z-index: 10;
    nav#nav-principal {
      border-bottom-width: 2px;
    }
  }
}

#nav-principal {
  position: relative;

  margin: auto;

  border-bottom-width: 0;
  border-bottom-style: solid;
  border-bottom-color: transparent;
  transition: border .2s linear;

  > ul {
    font-size: 0;
    width: $largeur_fenetre_grand;
    padding: 0 20px;
    margin: 0 auto;
    text-align: center;
    position: relative;
  }

  .navigation_link_p {
    text-transform: uppercase;
  }

  .navigation_button_p {
    > ul {
      border-top-style: solid;
      border-top-width: 2px;
      max-width: 1200px;
      width: 490px;
      position: absolute;
      text-align: left;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: flex-start;
      max-height: 215px;
      max-height: 45vh;
      box-shadow: 0 8px 14px 0 rgba(0, 0, 0, .25);
      left: 0;
      padding: 0;
      visibility: hidden;
      z-index: -1;
      opacity: 0;
      transition: opacity .2s linear, z-index 0s linear .2s, visibility 0s linear .2s, transform .2s linear;
      overflow-y: auto;
      padding-bottom: 20px;
      padding-left: 15px;
      padding-right: 15px;
      transform-origin: top center;
      transform: scaleY(0);
    }

    &:hover {
      > ul {
        transition-delay: 0s;
        opacity: 1;
        z-index: 10;
        visibility: visible;
        transform: scaleY(1);
      }
    }
  }

  .sous_navigation_button_p {
    display: block;
    width: 100%;
    max-width: 220px;

    > ul {
      position: static;
      /*display: flex;
                flex-direction: column;*/
      display: block;

      > li {
        display: block;

        > a {
          line-height: 1.1;
          padding: 0 5px 6px 10px;
          white-space: normal;
        }
      }
    }
  }

  .sous_navigation_link_p {
    font-size: 16px;
    text-transform: none;
    margin-top: 5px;
    margin-bottom: 0;
    font-weight: bold;
    padding: 5px;
    white-space: normal;

    > span {
      max-width: 100%;
      display: inline-block;
      white-space: initial;
    }
  }

  li {
    display: inline-block;
    font-size: 1rem;
  }

  a {
    padding: 12px 25px;
    width: 100%;
    display: block;
    font-family: 'Dosis', 'Poppins', 'Arial', sans-serif;
    font-size: 16px;
    font-weight: bold;
    white-space: nowrap;
  }
}

/* Hack debug internet explorer > 9 */
@media screen and (min-width: 0 \0
) {
  nav#nav-principal .navigation_button_p > ul {
    height: 300px;
  }
}

/*-----------------------------
    PRODUITS
-------------------------------*/

/*Général*/

.produits, #fiche-produit {
  .produit_etiquette {
    display: inline-block;
    width: auto;
    height: auto;
    top: 15px;
    left: 0;

    &::after {
      display: none;
    }

    .h3, h3 {
      font-size: 15px;
      padding: 2px 7px 0;
      line-height: 1.9;
      text-align: center;
      font-weight: normal;
    }
  }
}

.template_boutique_all {
  .boutique_header {
    margin: 0 0 20px;
  }

  .produits {
    position: relative;
    z-index: 1;
    padding: $padding_produits;
  }

  .productImageWrap {
    position: relative;
    overflow: hidden;
    font-size: 0;
    line-height: 0;
  }

  .mask {
    position: absolute;
    width: 100%;

    a {
      opacity: 0;
    }
  }

  a.zoom {
    opacity: 0;

    &::before {
      text-align: center;
      color: inherit;
    }
  }

  .oldprix {
    text-decoration: line-through;
  }

  .produits-bloc-image {
    position: relative;
    display: inline-block;
    text-align: center;
    width: 100%;
    border-width: 0;
    border-style: solid;

    img {
      transition: opacity .2s linear;
      opacity: 1;
    }
  }

  .nomprod {
    width: 100%;
    order: 0;
    text-align: left;
    text-decoration: none;
    line-height: 13px;
    font-size: 14px;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 10px;
    float: left;

    &::before {
      display: none;
    }
  }

  .attr {
    width: 100%;
    text-align: left;
    position: static;
    margin: 0 0 10px 0;
    order: 4;

    select {
      position: static;
      max-width: 100%;
      width: auto;
      margin: 0;
    }
  }

  .prix {
    width: 100%;
    order: 1;
    margin-top: 0;
    text-align: right;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .prixprod {
      font-size: 16px;
      font-weight: bold;
      color: inherit;
      margin: 0 10px 0 0;
      display: inline-block;
      text-align: inherit;
    }

    .oldprix {
      font-size: 14px;
      color: inherit;
      opacity: .8;
      margin: 0 10px 0 5px;
      text-decoration: line-through;
      display: inline-block;
      text-align: inherit;
    }

    .prixmention {
      font-size: 14px;
      color: inherit;
      margin: 0 10px 0 5px;
      display: inline-block;
      text-align: inherit;
    }
  }

  .note-produit {
    order: 3;
    width: 100%;
  }

  .block-btn-addbasket {
    order: 4;
  }

  .productContentWrap {
    margin: 20px 0;
    min-height: 68px;

    > * {
      flex: 0 1 auto;
    }

    &::after {
      content: '';
      display: table;
      clear: both;
    }
  }

  .container-etiquette.with-etiquette ~ .remise {
    top: 55px;
  }

  .remise {
    position: absolute;
    z-index: 100;
    margin-top: 0;
    padding: 0;
    right: auto;
    left: 5px;
    top: 15px;
  }

  .text-remise {
    font-size: 15px;
    padding: 2px 7px 0;
    line-height: 1.9;
    text-align: center;
    font-weight: normal;
  }

  /* Produits - vue */
  .addbasket, .hors-stock {
    position: absolute;
    width: 0;
    height: 0;
    background: transparent;
    color: transparent;
    top: 0;
    left: 50%;
    margin: 0 0 0 -47px;
    padding: 50% 0 0 0;
    float: none;
    opacity: 1;
    display: block;
    transition: padding-top .2s linear, top .2s linear;
    z-index: 10;
  }

  .addbasket.next-select {
    margin: 0 0 0 -47px;
    float: none;
  }

  .inside-addbasket i::before {
    content: "\f291";
  }

  .mask, .inside-addbasket, .inside-hors-stock {
    position: absolute;
    height: 33px;
    width: 45px;
    line-height: 33px;
    text-align: center;
    top: 0;
    left: 50%;
    opacity: 0;
    margin-left: 2px;
    transition: opacity .2s linear, background-color .2s linear, color .2s linear, top .2s linear;
    padding: 0;
  }

  .inside-addbasket, .inside-hors-stock {
    left: 0;
  }

  .mask .zoom {
    left: 0;
  }

  .inside-hors-stock {
    opacity: 0;
    transition: opacity .2s linear, top .2s linear;

    &::before {
      width: 45px;
      text-align: center;
      height: 33px;
      font-size: 16px;
      line-height: 33px;
      position: absolute;
      left: 0;
      top: 0;
      text-indent: 0;
      content: "\f00d";
      font-family: fontAwesome, sans-serif;
      color: #fff;
      background: red;
      opacity: .5;
    }
  }

  .inside-addbasket, .inside-hors-stock {
    position: absolute;
    border-width: 0;
    width: 45px;
    height: 33px;
    text-indent: -9999px;
    overflow: hidden;
    z-index: 10;
    right: 0;
    top: auto;
    bottom: -33px;
    margin: 0;

    i {
      width: 45px;
      text-align: center;
      height: 33px;
      line-height: 33px;
      position: absolute;
      left: 0;
      top: 0;
      text-indent: 0;
    }
  }
  .inside-hors-stock {
    bottom: -23px;
    left: -59px;

  }
  .productImageWrap {
    //width: 100%;
    overflow: visible;
    position: relative;

    .zoom {
      position: absolute;
      height: 33px;
      width: 45px;
      line-height: 33px;
      text-align: center;
      transition: all .2s linear;
      padding: 0;
      opacity: 0;
      top: 50%;
      left: 50%;
      z-index: 10;
      display: block;
      margin: 0;

      &::before {
        line-height: 32px;
        font-size: 15px;
        bottom: 0;
        top: auto;
        text-align: center;
        content: "\f06e";
      }
    }
  }

  .produits:hover, .produits:focus {
    &.produits-image-loaded .produits-bloc-new-image + img {
      opacity: 0;
    }

    .mask {
      opacity: 1;
      top: 50%;
    }

    .inside-addbasket, .inside-hors-stock {
      opacity: 1;
    }

    .productImageWrap .zoom {
      opacity: 1;
    }

    .productImageWrap .mask .zoom {
      top: 0;
    }
  }


}

.safari .template_boutique_all .produits {
  padding: 0 $padding_produits $padding_produits $padding_produits;
}

.template_boutique_accueil {
  margin: 20px auto;

  .produits {
    padding-bottom: 20px;

    .productImageWrap {
      margin-bottom: 20px;
    }

    .mask {
      top: 50%;
      opacity: 1;

      .zoom {
        top: 0;
        left: 0;
      }
    }
  }

  &.accueil_boutique_template_1 {
    @include produitAccueilTaille(100px, 3, 30px);

    .bx-controls-direction a {
      &.bx-next {
        right: -50px;
      }

      &.bx-prev {
        left: -50px;
      }
    }

    .bx-pager {
      display: none;
    }

    .produits {
      .mask {
        //margin-top:-3px;
      }
    }
  }
}

.template_boutique_1 .produits {
  .productContentWrap {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
}

.template_boutique_2 .produits {
  .productContentWrap {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
}

.template_boutique_3 {
  $padding-produit: 10px;

  .produits {
    padding: $padding-produit;
  }

  .produits-bloc-image {
    img {
      padding-right: $padding-produit;
    }

    border-top: none;
    border-left: none;
    border-bottom: none;
  }

  .productImageWrap {
    z-index: auto;
  }

  .productContentWrap {
    position: static;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .produit_etiquette {
    position: absolute;
  }

  .container-etiquette {
    left: -$padding-produit;
  }

  .remise {
    left: 0;
    top: 15px + $padding-produit;
  }

  .container-etiquette.with-etiquette ~ .remise {
    top: 55px + $padding-produit;
  }

  .nomprod {
    width: 100%;
    float: none;
    margin: 0 0 10px;

    a {
      font-size: 18px;
      font-weight: 700;
    }
  }

  .produits.is_etiquette .nomprod {
    margin-top: 0;
  }

  .prix {
    float: none;
    text-align: left;
    width: 100%;
    margin-bottom: 10px;
    justify-content: flex-start;

    .prixprod, .oldprix {
      text-align: left;
    }

    .prixprod {
      font-size: 18px;
      font-weight: bold;
    }

    .oldprix {
      font-size: 14px;
    }
  }

  .desc {
    order: 2;
    margin: 0 0 10px;
    line-height: 22px;
    font-size: 14px;

    &:empty {
      display: none;
    }
  }

  .attr {
    order: 3;
    width: 100%;
    float: none;

    &:empty {
      display: none;
    }

    select {
      float: none;
    }
  }

  //Ajouter au panier / zoom
  .zoom {
    margin-left: -26px;
  }

  .block-btn-addbasket {
    order: 4;
    margin: 0;
    text-align: left;
    justify-content: flex-start;
    flex-direction: row;
  }

  .addbasket, .hors-stock {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    padding: 0;
    margin: 0;
    opacity: 1;
    width: auto;
    height: auto;

  }

  .addbasket.next-select {
    margin: 0;
  }

  .inside-addbasket, .inside-hors-stock {
    position: relative;
    width: auto;
    height: auto;
    text-indent: 0;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    opacity: 1;
    line-height: 1;
    text-align: left;

    i {
      height: auto;
      width: 40px;
      top: 5px;
      left: 0;
      bottom: 5px;
      border-right: 1px solid;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      line-height: 1;
    }
  }

  .inside-addbasket {
    padding: 8px 20px 8px 50px;
  }

  .hors-stock {
    color: inherit !important;

    .inside-hors-stock {
      line-height: 33px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      &::before {
        display: inline-block;
        position: relative;
        margin-right: 10px;
        width: 25px;
        height: 25px;
        line-height: 25px;
      }
    }

    .sub-hors-stock {
      display: inline-block;
    }
  }
}

.template_boutique_4 {
  .container-etiquette {
    margin: -1px 0 0;
  }

  .produit_etiquette {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;

    .titre-etiquette {
      width: 100%;
      text-align: center;
      font-size: 15px;
      padding: 5px 10px;
    }
  }

  .note-produit {
    padding: 0;
  }

  .productContentWrap {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: static;
  }

  .prix {
    margin: 10px 0;
    width: 100%;
    display: block;
    padding: 0;
    float: none;

    .oldprix, .prixprod {
      text-align: left;
    }

    .oldprix {
      margin: 0;
    }
  }

  .nomprod {
    width: 100%;
    float: none;
    padding: 0;
    font-weight: bold;
    margin-top: 20px;

    a {
      text-align: left;
      font-size: 14px;
    }
  }

  .desc {
    margin: 0 0 10px;
    padding: 0;
  }

  .attr {
    float: none;
    padding: 0;
    width: 100%;
  }
}

.template_boutique_5 {
  .addbasket, .hors-stock {
    padding-top: calc(25% + #{$padding_produits/2});
    left: 25%;
    margin-top: 0;
    margin-left: -50px;
  }

  .productContentWrap {
    margin-top: 0;
  }

  .nomprod {
    width: 50%;
    float: left;
    padding: 0;
    padding-left: 5px;
  }

  .note-produit {
    width: 50%;
    padding-left: 5px;
  }

  .prix {
    float: left;
    width: 50%;
    padding-left: 5px;

    .prixprod, .oldprix {
      text-align: left;
    }

    .oldprix {
      margin: 0;
    }
  }

  .attr, .desc {
    clear: both;
    margin: 0 0 10px;
    display: block;
  }

  .attr {
    float: none;
    padding-top: 10px;
    width: 100%;

    select {
      float: none;
    }
  }

  .desc {
    display: block !important;
  }

  .text-remise {
    font-size: 12px;
    line-height: 1.2;
  }


  .container-etiquette {
    top: 0;

    &.with-etiquette ~ .remise {
      top: 45px;
    }
  }

  .produit_etiquette .titre-etiquette {
    font-size: 12px;
    line-height: 1.4;
    text-align: left;
  }

}

.produit_etiquette, .produit_etiquette2, .produit_etiquette3 {
  width: 100%;
  max-width: 100% !important;

  .h3 {
    padding: 8px 0;
    font-size: 1.2rem;
  }
}

//Menu de Gauche
aside.sidebar {
  padding: 0;
}

#nav-static-principal {
  > ul {
    display: block !important;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .menu_burger {
    display: none !important;
  }

  .navigation_button_p {

    .navigation_link_p {
      padding: 16px 20px;
      width: 100%;
      display: inline-block;
      margin: 0;
      font-size: 18px;
      text-transform: uppercase;
      font-weight: bold;
      font-family: 'Dosis', sans-serif;

      &.dropDown {
        display: none !important;
      }
    }

    > ul {
      display: block !important;

      > .sous_navigation_button_p {
        > .sous_navigation_link_p {
          font-size: 16px;
          font-weight: 600;
          font-family: 'Dosis', sans-serif;
          line-height: 18px;
          margin: 0;
          padding: 14px 0;
          text-transform: uppercase;
          border-bottom-style: solid;
          border-bottom-width: 1px;

          &.dropDown {
            border: none;
          }
        }

        &.actif, &.sousmenu_ouvert {
          > ul {
            display: block !important;
            height: auto !important;
          }

          > .dropDown {
            &::before {
              content: "\f068";
            }
          }
        }

        &:last-child {
          > .sous_navigation_link_p {
            border: none !important;
          }
        }

        > ul {
          padding: 15px 10px !important;
        }

        .sous_navigation_button_p {

          .sous_navigation_link_p {
            font-size: 14px;
            font-family: 'Poppins', sans-serif;
            line-height: 18px;
            padding: 10px 0;
            width: 100%;
          }
        }
      }
    }

    ul {
      padding: 0 20px;
    }
  }

  .sous_navigation_button_p {
    position: relative;

    .sous_navigation_link_p {

    }
  }

  a {
    display: block;

    &::before {
      content: '';
      display: none;
    }
  }

  .dropDown {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    opacity: .7;
    transition: opacity .2s linear;
    text-align: center;
    width: 35px;

    &::before {
      content: "\f067";
    }

    &:hover {
      opacity: 1;
    }
  }
}

/*-----------------------------
    FICHES PRODUITS
-------------------------------*/
#fiche-produit {
  .table-feature {
    padding: 10px 20px;
  }
}

/* miniatures photos */
.template_fiche_produit {
  .bloc-suggestions {
    > .h4, > h4 {
      margin: 21px 0;
    }
  }
}

#fiche-produit {
  .previews {
    .previous, .next {
      width: auto;
    }
  }

  .stock, .notification_product {
    margin: 10px 0;
    border: 1px solid;
    text-align: center;
    padding: 0.4rem 1rem;
    font-size: 12px;
    height: auto;
  }

  .remise-produit {
    width: auto;
  }

  .wrap-description {
    position: relative;

    .sociaux {
      border: none;
    }

    .reference, .note-produit {
      width: 100%;
    }

    .block-fiche-titre {
      display: none;
    }

    .block-fiche-brand {
      width: 100%;

      a {
        text-align: left;
      }
    }

    .reservation {
      margin-bottom: 15px;

      .block-addbasket a {
        margin-left: 0;
      }
    }

    .prix {
      border: none;
      padding: 8px 0 0;
      margin-bottom: 0;

      .prixprod {
        font-size: 18px;
      }

      .oldprix {
        font-size: 15px;
        color: inherit;
      }
    }

    .block-price {
      margin: 0 0 15px;
    }

    .reservation__addbasket .bloc-quantite {
      margin-bottom: 15px;
    }

    .block-addbasket {
      float: none;
      width: auto;
      display: inline-block;

      .confirm_payment_offline {
        margin-bottom: 15px;
      }

      a {
        display: inline-block;
        font-size: 14px;
        height: auto;
        line-height: 26px;
        padding: 9px 20px 9px 50px;
        text-transform: uppercase;
        font-family: 'Dosis', sans-serif;
        font-weight: 700;
        margin: 0 5px 0 22px;
        position: relative;

        i {
          height: auto;
          width: 40px;
          top: 5px;
          left: 0;
          bottom: 5px;
          border-right: 1px solid;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
          line-height: 1;
          position: absolute;
        }
      }
    }

    .pro-pannier {
      margin: 0 0 15px;

      &::after {
        content: '';
        display: table;
        clear: both;
      }
    }

    .description, .details-ficheprod {
      margin: 0 0 15px;
      width: 100%;
    }

    .details-ficheprod {
      .button {
        display: inline-block;
        margin: 0;
      }
    }

    .declinaison-attribute-group, .product_options-group {
      padding-top: 0;
      padding-bottom: 0;
      margin: 15px 0 0;

      &:last-child {
        margin-top: 15px;
      }

      > label:first-child {
        line-height: 30px;
        margin: 0;
        font-size: 18px;
        font-weight: 700;
        font-family: 'Dosis', sans-serif;
        width: 100%;
      }

      select {
        height: 40px;
        width: 100%;
        padding: 0 12px;
        margin: 0;
        font-size: 14px;
      }

      select[multiple] {
        height: 60px;
      }
    }

    .declinaison-attribute-group__item, .product_options-group__item {
      width: 100%;
      margin: 0;
    }

  }

  .radio_unique, .radio_color {
    margin: 5px 5px 0;
    box-shadow: none;
    border-width: 1px;
    border-style: solid;
    border-radius: 0;
    transition: border-color .2s linear, opacity .2s linear;

    &:first-child {
      margin-left: 0;
    }

    &:hover, &.radio-checked, &.radio-color-checked {
      border-color: initial !important;
      opacity: 1 !important;
    }
  }

  .wrap-images {
    .grande-image {
      margin-bottom: 20px;
      border-width: 1px;
    }
  }

  #savoirplus, #produit-comment {
    border-width: 1px;
    border-style: solid;
    padding: 0;

    > h4, > .h4 {
      width: 100%;
      margin: 0;
      padding: 24px 50px 24px 10px;
      font-size: 20px;
      font-weight: bold;
      font-family: "Dosis", sans-serif;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      position: relative;
    }

    .see-more-details {
      position: absolute;
      width: 40px;
      height: 40px;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      line-height: 40px;
      font-size: 20px;
      cursor: pointer;
      text-align: center;

      &::before {
        content: "\f055";
        font-family: fontAwesome, sans-serif;
      }

      &.actif::before {
        content: "\f056";
      }
    }
  }

  #savoirplus {
    margin-top: 20px;

    .details {
      padding: 10px 15px;
    }
  }

  #produit-comment {
    margin-top: -1px;

    .details-avis {
      margin: 10px 15px;
      display: none;
    }

    .message-wrap {
      box-shadow: none;
    }

    #addcomment > div {
      box-shadow: none !important;
    }

    .form-control {
      border-radius: 0;
      box-shadow: none;
    }

    .msg-informations {
      clear: both;
      float: none !important;
      margin: 0 !important;
    }

    [itemprop="description"] {
      clear: both;
      float: none !important;
      margin-top: 15px;

      > p {
        margin: 0;
        padding: 15px 20px;
        border-left-width: 1px;
        border-left-style: solid;
      }
    }

    .moyenne_etoiles {
      > p, > small {
        display: inline-block;
      }

      > p {
        margin-left: 5px;
      }

      small {
        &::before {
          content: '(';
          display: inline-block;
        }

        &::after {
          content: ')';
          display: inline-block;
        }
      }
    }
  }
}

.template_fiche_produit_1 #fiche-produit {

  .wrap-images, .wrap-description {
    width: 50%;
    padding: 0 15px;
    margin: 0;
    @media #{$small-and-down} {
      padding: 0;
    }
  }

  .wrap-description {
    display: flex;
    flex-wrap: wrap;

    > * {
      flex: 1 1 auto;
    }

    .ajouter-panier {
      max-width: 100%;
    }

    .triangle-ficheprod {
      display: block !important;
      position: relative !important;

      .remise-produit {
        border-radius: 0;
        padding: 6px 12px;
        font-size: 15px;
        height: auto;
        right: 0;
        left: auto;
        top: 4px;
      }
    }
  }

  //Wrap images
  .produit_etiquette {
    width: auto;
    position: absolute;

    .titre-etiquette {
      font-size: 15px;
    }
  }
  .wrap-images {


    .triangle-ficheprod {
      display: none;
    }

    .grande-image {
      border-style: solid;
      padding: 15px;

      > a {
        > img {
          max-height: 550px;
        }
      }
    }

    #sliderImagePreviews {
      height: 150px;

      img {
        width: 100%;
        height: auto;
      }

      li {
        width: 150px;
        height: 150px;
        border-width: 1px;
        border-style: solid;
        padding: 10px;
      }
    }

    .previews {
      width: calc(100% - 75px);
      margin: 0 auto;

      @media #{$medium-and-down} {
        width: 100%;
      }

      > span {
        width: 30px;
        height: 30px;
        line-height: 30px;
        background-image: none;
        text-indent: 0;
        text-align: center;
        transition: background .2s linear, color .2s linear;
        display: block;
        font-size: 1px;

        i {
          display: none;
        }

        &.previous {
          left: -35px;
        }

        &.next {
          right: -35px;
        }

        &::before {
          content: '';
          font-family: 'fontAwesome', sans-serif;
          display: inline-block;
          font-size: 30px;
          color: inherit;
          opacity: .3;
        }

        &:hover {
          &::before {
            opacity: .7;
          }
        }

        &.previous {
          &::before {
            content: "\f104";
          }
        }

        &.next {
          &::before {
            content: "\f105";
          }
        }
      }
    }
  }
}

.modal {
  z-index: 2050;
}

.reservation__list > * {
  flex: 1 1 auto;
}

.template_fiche_produit_2 #fiche-produit {
  #savoirplus {
    width: 70%;
    padding-right: 20px;
    border: none;

    > h4, > .h4 {
      border-width: 1px;
      border-style: solid;
    }

    .details {
      border-width: 0 1px 1px 1px;
      border-style: solid;
    }
  }

  .wrap-images {
    width: 70%;
    padding: 0 20px 0 0;

    .sliderImageFicheProd {
      border-width: 1px;
      border-style: solid;
      padding: 10px;
    }

    #sliderImagePreviews {
      > a {
        border-width: 1px;
        border-style: solid;
        opacity: 1;

        &:first-child {
          margin-top: 0;
        }
      }
    }

    .grande-image {
      .triangle-ficheprod {
        display: inline-block !important;
        text-align: center;
        width: auto;
        font-size: 1rem;
        top: 40px;
        right: 0;

        .remise-produit {
          position: relative;
          display: block;
          width: auto;
          height: auto;
          border-radius: 0;
          font-size: 15px;
          font-weight: normal;
          padding: 2px 7px 0;
          line-height: 28px;
          text-align: center;
        }
      }

      .container-etiquette {
        margin: 0 0 15px;
        position: absolute;
        top: 15px;
        bottom: auto;
        left: auto;
        right: 0;
        display: inline-block !important;

        .produit_etiquette {
          top: 0;

          > h3, > .h3 {
            margin: 0;
            font-size: 15px;
            white-space: nowrap;
          }
        }
      }

      .container-etiquette {

      }
    }
  }

  .wrap-description {
    padding: 12px;
    border-width: 1px;
    border-style: solid;

    .container-etiquette {
      display: none;
    }

    .reference, .note-produit {
      margin: 0 0 15px;
      width: 100%;
      text-align: center;
      display: block;
    }

    .note-produit {
      margin: 0;
    }

    .block-fiche-titre-brand {
      margin: 0 0 15px;

      .brand {
        margin: 0;
      }
    }

    .pro-pannier {
      .block-price {
        .prix {
          margin: 0;
          padding: 0;

          .oldprix {

          }
        }

        + .clearfix {
          display: none;
        }
      }

      .block-price {
        width: 100%;
        float: none;
        clear: left;
        padding: 0;
        text-align: center;
        margin: 15px 0 0;
      }

      .block-addbasket {
        clear: both;
        float: none;
        width: 100%;
        margin: 15px 0 0;
        text-align: center;

        .addbasket {
          margin: 0;
        }
      }
    }
  }

  .commentFicheProd {
    max-height: none;
    overflow: visible;
  }

  #addcomment {
    .form-group {
      padding: 0;
    }
  }
}

.template_fiche_produit_3 #fiche-produit {
  .wrap-images {
    .grande-image {
      border-width: 1px;
      border-style: solid;

      .bx-pager {
        display: none;
      }

      .triangle-ficheprod {
        display: inline-block !important;
        text-align: center;
        width: auto;
        font-size: 1rem;
        top: 40px;
        right: 0;

        .remise-produit {
          position: relative;
          display: block;
          width: auto;
          height: auto;
          border-radius: 0;
          font-size: 15px;
          font-weight: normal;
          padding: 2px 7px 0;
          line-height: 28px;
          text-align: center;
        }
      }

      .container-etiquette {
        margin: 0 0 15px;
        position: absolute;
        top: 15px;
        bottom: auto;
        left: auto;
        right: 0;
        display: inline-block !important;

        .produit_etiquette {
          top: 0;

          > h3, > .h3 {
            margin: 0;
            font-size: 15px;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .wrap-description {
    .pro-pannier {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      .declinaison {
        order: 1;
      }

      .prix {
        order: 2;
      }

      .information_stock {
        order: 3;
      }

      .bloc-quantite {
        order: 4;
        text-align: center;
      }

      .ajouter-panier {
        order: 5;
      }
    }

    .container-etiquette {
      display: none;
    }

    .block-addbasket {
      float: none;
      width: 100%;
      text-align: center;

      .addbasket {
        width: auto;
        display: inline-block;
      }
    }
  }

  .declinaisons-achat {
    width: 30%;
    padding: 12px;
    border-width: 1px;
    border-style: solid;
  }

  .container-etiquette {
    .produit_etiquette {
      > .h3, > h3 {

      }
    }
  }

  .details-descr-com {
    width: 70%;
    padding-right: 20px;

    .side-tabs {
      width: 100%;
      float: none;
      padding: 0;
      border-width: 1px;
      border-style: solid;
      letter-spacing: -.31em;

      a {
        margin: 0;
        letter-spacing: normal;
        border-right-width: 1px;
        border-right-style: solid;
      }
    }

    .contents-tabs {
      width: 100%;
      padding: 12px;
      border-width: 0 1px 1px 1px;
      border-style: solid;
      margin-left: 0;
    }

    #savoirplus, #produit-comment {
      margin: 0;

      > h4, > .h4 {
        padding-top: 0;
      }

      &, & > h4, & > .h4 {
        border-width: 0;
      }

      > h4, > .h4, > [class*="details"] {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

/* Permet de bien afficher le hover des images fiches produits pour le zoom */
.content_p .zoomPupImgWrapper > img, .content_p .zoomWrapperImage > img {
  max-width: none !important;
}

.triangle-ficheprod {
  position: absolute !important;
  right: 0;
  top: 0;
}

.template_fiche_produit_2 #fiche-produit .wrap-images .triangle-ficheprod {
  top: 0;
  right: 0;
}

/*-----------------------------
    SLIDER
-------------------------------*/

body .bx-wrapper .bx-controls .bx-controls-direction,
body .banner_diaporama .bx-wrapper .bx-controls-direction,
.accueil_boutique_template_1 .bx-wrapper .bx-controls-direction {
  > a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    margin-top: -20px;
    background-image: none;
    text-indent: 0;
    text-align: center;
    transition: background .2s linear, color .2s linear;
    font-size: 0;

    &::before {
      content: '';
      font-family: 'fontAwesome', sans-serif;
      display: inline-block;
      font-size: 25px;
    }

    &.bx-prev {
      &::before {
        content: "\f104";
      }
    }

    &.bx-next {
      &::before {
        content: "\f105";
      }
    }
  }
}

body .banner_diaporama .bx-wrapper .bx-controls-direction a {
  text-indent: 0;
}

.accueil_diaporama_template {
  .bx-pager {
    display: none;
  }

  .bx-wrapper {
    margin-bottom: 0;
  }

  .slide-active {
    .slide-text {
      p {
        animation-duration: .8s;
        animation-name: sliderP;
        opacity: 1;
      }

      .content-slide-text a {
        &.addcommentaires, &.slider-see-more {
          opacity: 1;
          animation-duration: .8s;
          animation-name: addCommentaires;
        }
      }
    }
  }

  .slide > div:hover, .slide > li:hover {
    &:not(.slide-active) {
      .slide-text {
        opacity: 1;

        p {
          animation-duration: .8s;
          animation-name: sliderP;
          opacity: 1;
        }

        .content-slide-text a {
          &.addcommentaires, &.slider-see-more {
            opacity: 1;
            animation-duration: .8s;
            animation-name: addCommentaires;
          }
        }
      }
    }
  }

  .slide-text {
    box-sizing: border-box;

    p {
      background: transparent;
      color: #fff;
      font-family: 'Dosis', sans-serif;
      font-weight: bold;
      font-size: 36px;
      bottom: 80px;
      left: 50px;
      right: 50px;
      text-align: left;
      top: auto;
      padding: 0;
      margin: 0;
      text-shadow: -2px 2px 11px rgba(0, 0, 0, .1);
      opacity: 0;
    }

    .content-slide-text {
      > a.slider-see-more {
        position: absolute;
        top: auto;
        left: 50px;
        right: auto;
        bottom: 25px;
        padding: 8px 20px;
        opacity: 0;
        transform-origin: left center;
      }

      > a.addcommentaires {
        position: absolute;
        top: auto;
        left: 50px;
        right: auto;
        bottom: 25px;
        padding: 8px 20px;
        font-weight: bold;
        font-size: 14px;
        font-family: 'Dosis', sans-serif;
        transition: background .2s linear, color .2s linear;
        opacity: 0;
        transform-origin: left center;

        ~ a.slider-see-more {
          left: 130px;
        }
      }
    }
  }

  &.accueil_diaporama_template_2 {
    .slide {
      padding-top: 25%;
      min-height: 200px;
      height: auto;
      position: relative;

      > div {
        position: absolute;
        top: 0;
        height: 100%;
        display: none;

        &:first-child {
          display: block;
        }

        .center-img-slider {
          height: 100%;
        }

        .slide-text {
          height: 100%;
        }
      }
    }

    .bx-controls-direction {
      display: none;
    }

    .bx-pager.bx-default-pager {
      display: block;
      position: absolute;
      bottom: 0;
      padding: 0;
      left: 0;
      width: 100%;
      line-height: 25px;

      .bx-pager-item {
        vertical-align: middle;
      }

      .bx-pager-link {
        background: transparent;
        border-width: 1px;
        border-style: solid;

        &:hover {
          border-width: 2px;
        }
      }
    }
  }

  &.accueil_diaporama_template_3 {
    .bx-controls-auto {
      display: none;
    }

    .slide {
      list-style: none;
    }

    #bx-pager {
      width: 100%;

      > a {
        width: 8%;
        margin: 0 1%;
        opacity: .5;
        transition: opacity .1s linear;
        border: 2px solid rgba(0, 0, 0, .1);
        max-width: 60px;

        &.active, &:hover {
          opacity: 1;
        }
      }

      &:hover {
        opacity: .8;
      }
    }
  }

  &.accueil_diaporama_template_4 {
    margin: 10px auto;

    .slide {
      li, li img {
        max-height: 480px;
        min-height: 480px;
      }

      .slider-text {
        max-width: 100%;
      }
    }
  }

  &.accueil_diaporama_template_5 {
    .slide {
      > div, > li {
        .slide-text {
          opacity: 0;
        }

        &.slide-active {
          .slide-text {
            opacity: 1;
          }
        }

        &:hover .slide-text {
          p {
            animation-duration: .8s;
            animation-name: sliderP;
            opacity: 1;
          }

          .content-slide-text a {
            &.addcommentaires, &.slider-see-more {
              opacity: 1;
              animation-duration: .8s;
              animation-name: addCommentaires;
            }
          }
        }
      }
    }
  }

  &.accueil_diaporama_template_4,
  &.accueil_diaporama_template_6 {
    .slide > li, .slide > div {
      &:hover .slide-text {
        p {
          animation-duration: .8s;
          animation-name: sliderP;
          opacity: 1;
        }

        .content-slide-text a {
          &.addcommentaires, &.slider-see-more {
            opacity: 1;
            animation-duration: .8s;
            animation-name: addCommentaires;
          }
        }
      }
    }
  }

  &.accueil_diaporama_full {
    .slide > div .slide-text {
      max-width: $largeur_fenetre_grand;
      width: 100%;
      margin: 0 auto;
      height: auto;
      position: relative;
    }

    .bx-controls-direction {
      max-width: $largeur_fenetre_grand;
      position: absolute;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
      bottom: 50%;
    }
  }
}

.bx-wrapper .bx-viewport {
  left: 0;
}

/*-----------------------------
    GAlLERIE
-------------------------------*/

.template_album {
  .list-photos {
    margin-top: 0;
  }

  .galerie_header {
    margin-top: 0;

    &:empty {
      display: none;
    }
  }

  .galerie {
    overflow: hidden;

    .mask {
      display: none;
    }

    .commentP {
      position: absolute;
      width: auto;
      max-width: 95%;
      top: 50px;
      padding: 0;
      bottom: auto;
      font-size: 12px;
      opacity: 0;
      transition: opacity .2s linear;
      transform: none;

      &::before {
        display: none;
      }

      p {
        padding: 2px 7px 0;
        line-height: 18px;
        font-size: 12px;
        font-weight: normal;
        text-align: left;
      }
    }

    .addcommentaires {
      position: absolute;
      width: auto;
      max-width: calc(100% - 50px);
      top: 15px;
      bottom: auto;
      left: 0;
      right: auto;
      padding: 2px 7px 0;
      line-height: 18px;
      font-size: 12px;
      font-weight: normal;
      text-align: right;
      opacity: 0;
      transition: opacity .2s linear;
      border-radius: 0;
      height: auto;
      border: none;
      display: block;
    }

    img {
      transition: transform .2s linear;
      transform-origin: center center;
    }

    &:hover {
      .commentP {
        top: 50px;
        bottom: auto;
        width: auto;
        opacity: 1;
      }

      .addcommentaires {
        opacity: 1;
      }

      img {
        transition: transform 15s linear;
        transform: scale(1.5, 1.5);
      }
    }
  }

  &.template_album_1,
  &.template_album_3 {
    .galerie {
      &:hover {
        img {
          &.img-hauteur {
            transform: translateX(-50%) scale(1.5, 1.5);
          }

          &.img-largeur {
            transform: translateY(-50%) scale(1.5, 1.5);
          }
        }
      }
    }
  }

  &.template_album_4,
  &.template_album_5 {
    .galerie {
      .commentP {
        position: static;
        width: 100%;
        max-width: none;
        opacity: 1;

        p {
          font-size: 15px;
          padding: 16px 20px;
          width: 100%;
        }
      }
    }
  }
}

/*-----------------------------
    LIVRE D'OR
-------------------------------*/

.list-messages {
  margin: 20px 0;
}

.template_livreor {
  &.template_livreor_1 {
    .message_lo {
      padding: 20px;
      border: 1px solid;

      .sous-titre {
        margin-top: 0;
        padding: 9px 0;
        max-width: calc(100% - 80px);
      }

      .note {
        display: inline-block;
        width: auto;
        padding: 8px 12px;
        margin: 0;
        border-width: 1px;
        border-style: solid;
        position: absolute;
        top: 20px;
        right: 20px;
      }

      .message {
        display: block;
        width: 100%;
        padding-left: 15px;
        padding-top: 15px;
        border-left-style: solid;
        border-left-width: 1px;

        p {
          margin: 0;
        }
      }
    }
  }

  &.template_livreor_2 {
    .message_lo {
      .IDLO {
        background: transparent;
        border-width: 1px 1px 1px 3px;
        border-style: solid;
        transition-duration: .2s;
      }

      .contenuMsgLO {
        border-width: 1px 1px 1px 0;
        border-style: solid;
        transition-duration: .2s;

        &::before, &::after {
          content: '';
          position: absolute;
          display: block;
          width: 1px;
          left: 0;
          transition-duration: .2s;
        }

        &::before {
          top: 0;
          height: 40px;
        }

        &::after {
          top: 66px;
          bottom: 0;
        }
      }

      .blcFleche {
        top: 38px;
        left: -17px;

        &::before {
          border-width: 1px;
          border-style: solid;
          margin-left: 7px;
          transition-duration: .2s;
        }
      }

      &:nth-child(2n+1) {
        .contenuMsgLO {
          background: transparent;

          .blcFleche::before {
            background: transparent;
          }
        }
      }

      &:hover {
        .blcFleche {
          &::before {
            border-width: 1px;
          }
        }
      }
    }
  }

  &.template_livreor_3 {
    .message_lo {
      background: transparent;
      border-width: 1px;
      border-style: solid;
    }
  }
}

/*-----------------------------
    CONTACT / FORMULAIRES
-------------------------------*/

.template_contact {}

.template_contact_2 {
  input[type="text"], select, textarea {
    height: 40px;
    width: 100%;
    padding: 0 12px;
    margin: 5px 0 0;
    font-size: 14px;
    border-radius: 0;
    border-width: 1px;
    border-style: solid;

    &:hover, &:focus {
      margin: 5px 0 0;
    }
  }

  textarea {
    &, &:hover, &:focus {
      height: 100px;
    }
  }
}

.template_contact_3 {
  input:not(.button):not([type="file"]), textarea {
    border-width: 1px;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.template_contact_4 {
  .tmp4blocC {
    input:not(.button) {
      padding-top: 0;
      padding-bottom: 0;
    }

    select {
      height: 40px;
    }

    &.s6 .form-group > div.messageformError {
      width: 150px;
    }
  }
}

.template_contact_5 {
  label, .form-group, input, textarea {
    text-align: left;
    font-size: 12px;
  }
}



input[type="text"], input[type="password"], select, textarea {
  height: 40px;
  width: 100%;
  padding: 0 12px;
  margin: 5px 0 0;
  font-size: 14px;
  border-radius: 0;
  border-width: 1px;
  border-style: solid;
  line-height: 40px;
}

textarea {
  height: 100px;
  font-size: 12px;
  font-family: inherit;
}

.form-group {
  .groupe-radio,
  .groupe-checkbox {
    display: block;
    width: 100%;
    position: relative;
    margin: 8px 0;

    .formError {
      ~ label::before, ~ label::after {
        top: 14px;
      }
    }

    input {
      position: absolute;
      opacity: 0;

      &:checked + label {
        &::before {
          content: '\2713';
        }

        &::after {
          opacity: 0;
          transform: translateY(-50%) scale(5);
          width: 0;
          height: 0;
          visibility: visible;
        }
      }
    }

    label {
      margin-left: 0 !important;
      padding: 0 0 0 20px;

      &::before {
        content: '';
        display: inline-block;
        width: 15px;
        height: 15px;
        position: absolute;
        left: 0;
        top: 5px;
        z-index: 2;
        border-width: 2px;
        border-style: solid;
        transition: border .2s ease;
        font-size: 18px;
        font-weight: bold;
        text-indent: 1px;
        line-height: 8px;
      }

      &::after {
        content: '';
        position: absolute;
        z-index: 0;
        width: 15px;
        height: 15px;
        left: 0;
        top: 5px;
        opacity: .5;
        border-radius: 50%;
        transform-origin: center center;
        visibility: hidden;
        transition: transform .6s ease, opacity .6s ease, visibility 0s linear, width 0s linear .6s, height 0s linear .6s;
      }

      &:hover {
        &::before {

        }
      }
    }
  }
}


.groupe-radio label::before {
  border-radius: 50%;
}

.groupe-checkbox::before {

}

body {
  .roundedgraymsg {
    border-radius: 0;
  }

  .form-newsletter, .formulaires_perso {
    .qs_form {
      border-width: 0 !important;
      margin-top: 15px;
    }

    input[type="file"] {
      border-width: 0 !important;
    }
  }
}

/*-----------------------------
    BLOG
-------------------------------*/

.template_blog {
  &.template_blog_1 {

    .two-column-blog, .one-column-blog {
      display: flex;
      flex-direction: row-reverse;
      flex-wrap: nowrap;
      align-items: flex-start;
      padding: 0 0 20px 0;
      margin: 0;
    }

    .blog-sidebar {
      width: 20%;
      border-width: 1px;
      border-style: solid;
      padding: 0;

      .blog-sidebar-bloc {
        float: none;
        width: 100%;

        h4 {
          margin: 0;
          padding: 16px 20px;
          font-size: 18px;
          text-transform: uppercase;
          font-weight: bold;
          font-family: 'Dosis', sans-serif;
          text-align: left;
        }

        > ul {
          margin: 0;
          padding: 0 20px;

          > li {
            margin: 0;
            position: relative;
            text-align: left;

            &::before {
              display: none;
            }

            > ul {
              padding: 15px 10px;

              > li {
                &::before {
                  display: none;
                }
              }

              a {
                font-size: 14px;
                font-family: 'Poppins', sans-serif;
                line-height: 18px;
                padding: 10px 0;
                width: 100%;
                text-align: left;
                border-bottom-width: 1px;
                border-bottom-style: solid;
              }
            }

            > a {
              font-size: 16px;
              font-weight: 600;
              font-family: 'Dosis', sans-serif;
              line-height: 18px;
              margin: 0;
              padding: 14px 0;
              text-transform: uppercase;
              border-bottom-width: 1px;
              border-bottom-style: solid;
              opacity: 1;
              text-align: left;

              > .titre-article {
                text-align: left;
              }
            }

            &:last-child {
              > a {
                border-bottom: none;
              }
            }
          }

          &:last-child {
            > a {
              border: none !important;
            }
          }
        }

        &.blog-tags {
          padding-bottom: 20px;

          > ul > li {
            margin: 0 10px;

            &:last-child > a {
              border-bottom-width: 1px;
              border-bottom-style: solid;
            }
          }
        }
      }
    }

    .blog-content {
      padding: 0;

      &.s8 {
        width: 80%;
        padding: 0 0 0 20px;
      }

      .titre-article,
      .titre-article-apercu,
      h2.titre-article-apercu,
      .h2.titre-article-apercu {
        position: static;
        background: transparent !important;
        width: 100%;
        font-size: 16px;
        line-height: 24px;
        text-transform: uppercase;
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
        padding: 0;
        display: block;
        text-align: left;
        margin: 0 0 20px !important;
      }

      .image-article {
        overflow: hidden;

        img {
          transition: transform .2s linear;
        }

        &:hover {
          img {
            transform: scale(1.2);
          }
        }
      }

      .description-courte-article {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;

        &-texte {
          font-size: 12px;
          line-height: 20px;
          margin: 0 0 15px;
        }
      }

      .date-article {
        margin: 0 0 15px;
        font-size: 12px;
      }

      .mots-cles {
        order: -1;
        margin: 0;

        .titre-mots-cles {
          display: none;
        }

        .short-article-tag {
          font-size: 12px;
          margin: 0 7px 0 0;
          line-height: 18px;

          &::before {
            display: none;
          }

          a::before {
            background: transparent;
            display: inline-block;
            width: auto;
            height: auto;
            position: static;
            font-family: fontAwesome, sans-serif;
            content: "\f02c";
            margin: 0;
          }
        }
      }

      .button-voir-plus {
        flex: 0 1 auto;
        width: auto;
        display: inline-block;
        align-self: flex-end;
      }

      .short-article-social {
        display: block;
        margin: 0;
        text-align: right;
        margin-top: 15px;
        padding-bottom: 15px;

        .hort-article-social-share {
          display: inline-block;
          padding: 0;
          margin: 0;
          float: none;
        }
      }

      .apercu-article-size {
        width: 49%;
      }

      .apercu-article-gutter {
        width: 2%;
      }

      .apercu-article {
        float: left;
        margin-bottom: 45px;
        width: 49%;
        padding: 5px;

        .titre-article-apercu {
          padding-top: 15px;
        }

        .bloc-article {
          margin: 0;
        }

        .description-courte-article {
          padding: 0;
        }
      }

      &::after {
        content: '';
        display: block;
        clear: both;
      }
    }

    .single-article {
      &.blog-content .titre-article {
        margin: 0 !important;
        width: 100%;
        padding: 0;
        height: auto;

        h1 {
          text-align: left;
          padding: 0;
          margin: 20px 0 15px !important;
        }
      }

      .date-article {
        padding: 0;
        margin-bottom: 15px;
        text-align: left;
      }

      .contenu-article {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;

        .mots-cles {
          margin-bottom: 15px;

          .short-article-tag {
            background: transparent;
          }
        }
      }
    }
  }
}

/*-----------------------------
    MON COMPTE
-------------------------------*/

#bloc-mon-compte {
  .sidebar {
    width: 100%;
    padding: 0;
    border-width: 0 0 0 1px;
    border-style: solid;

    nav {
      width: 100%;
      padding: 0;

      a {
        padding: 15px 20px;
        border-width: 1px 1px 0 0;
        border-style: solid;
      }
    }

    .contents-tabs {
      display: none;
    }
  }

  .bloc-mon-compte-content {
    padding: 15px;
    border-width: 1px 1px 1px 1px;
    border-style: solid;

    table {
      border-spacing: 0;
    }

    th {
      padding: 10px;
      text-transform: uppercase;
      font-weight: bold;
      border: none;
    }

    tbody > tr {
      height: auto;

      > td {
        padding: 15px 0;
      }
    }

    > table {
      > tbody > tr {
        &:nth-child(4n+1) {
          background-color: rgba(128, 128, 128, .1);
        }
      }
    }

    .infosclient {
      border-radius: 0;
      border: none !important;

      &_sous {
        height: 100%;
        width: 100%;
        border-width: 1px;
        border-style: solid;
        padding: 15px;

        table {
          tbody tr {
            &:nth-child(2n+1) {
              background-color: rgba(128, 128, 128, .1);
            }
          }

          th {
            text-transform: none;
          }

          td {
            padding: 15px 5px;

            .button {
              font-size: 13px;
              padding: 5px;
            }
          }
        }
      }
    }
  }
}

/*-----------------------------
    FOOTER
-------------------------------*/

footer {
  padding: 20px;
  text-align: center;
  clear: both;
  width: 100%;
  border-top-width: 1px;
  border-top-style: solid;
  position: relative;
}

#top_fleche {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 1000;
  display: none;

  a {
    line-height: 50px;
    height: 50px;
    width: 50px;
    border-radius: 3px;
    padding: 0;
    text-align: center;
    display: block;
    transition: background-color .2s linear, color .2s linear;

    &::before {
      content: "\f077";
      display: block;
      width: 100%;
      text-align: center;
      font-size: 18px;
      font-family: fontAwesome, sans-serif;
    }
  }
}

/*-----------------------------
    TEMPLATE PANIER
-------------------------------*/

.template-panier {
  &.template-panier-3 {
    border: none;
    width: 300px;
  }

  &.template-panier-2 {
    border: none;

    #header-panier {
      padding: 12px 25px;
      position: relative;
      text-transform: uppercase;
      font-family: 'Dosis', 'Poppins', 'Arial', sans-serif;
      font-size: 16px;
      font-weight: bold;

      .paniertoggle {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        padding: 12px 8px 12px 0;
        left: 0;
      }
    }

    #panier .fa-shopping-cart {
      vertical-align: top;
    }

    .count-live {
      left: 35px;
    }

    .paniertoggle .fa {
      font-size: 18px;
    }
  }

  #paniercommande .ajout_panier a.button {
    font-size: 18px;
  }
}

.cmonsite-panier-2 {
  #nav-principal {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    > ul {
      text-align: center;
      float: none;
      max-width: none;
      width: calc(1200px - 130px);
      margin: 0;
    }
  }
}

/*-----------------------------
    FLUX PANIER
-------------------------------*/

#flux-panier.template_flux_panier.template_flux_panier_1 {

  .logo-carrier-none {
    width: auto;
  }

  h4 {
    margin: 0;
  }

  div#information .list_address {
    margin: 0 0 20px;
  }

  .inscription, .connexion {
    height: auto;
  }

  #finish .info-complementaire-form select {
    width: 100%;
  }
}

.contents-tabs::before {
  border: none !important;
}

/*-----------------------------
    RECHERCHE
-------------------------------*/

#block-flottant-search {
  z-index: 2;

  .btn-search {
    border-radius: 0;
  }
}

.search-bar button {
  height: 42px;
}

/*********************************************************/
/********************* MEDIA QUERIES *********************/
/*********************************************************/

@media #{$xlarge-and-down} {
  .wrapper, nav#nav-principal > ul, #headercontent, .accueil_diaporama_template.accueil_diaporama_full .bx-controls-direction, #footer #pub {
    width: $large-screen - 80;
  }
  /* Menu */
  nav#nav-principal .navigation_button_p > ul,
  .accueil_diaporama_template.accueil_diaporama_full .slide-text {
    max-width: $large-screen - 80;
  }
  /* fiche produit */
  #fiche-produit .wrap-images .previews .list-previews {
    width: 69%;
  }
}

@media #{$large-and-down} {
  .wrapper, nav#nav-principal > ul, #headercontent, .accueil_diaporama_template.accueil_diaporama_full .bx-controls-direction, #footer #pub {
    width: $medium-screen - 80;
  }
  /* Menu */
  nav#nav-principal .navigation_button_p > ul,
  .accueil_diaporama_template.accueil_diaporama_full .slide-text {
    max-width: $medium-screen - 80;
  }
  /* Fiche produit */
  #fiche-produit .wrap-images .previews .list-previews {
    width: 64%;
  }
  .template_fiche_produit {
    &.template_fiche_produit_2 #fiche-produit {
      .grande-image {
        &, &.is_etiquette {
          #sliderImagePreviews {
            margin-top: 0;
          }
        }
      }
    }
  }
  /* Produits */
  .template_boutique_all {
    &.template_boutique_5 {
      .produits {
        @include elemTailleCol(2, 1.25, true);
      }
    }
  }
  /* Diaporama accueil */
  .accueil_diaporama_template {
    &.accueil_diaporama_template_4 {
      margin: 10px auto;

      .slide {
        li, li img {
          max-height: 350px;
          min-height: 350px;
        }
      }
    }
  }
}

@media #{$medium-and-down} {
  #headercontent {
    height: auto !important;
    padding: 15px 0;
  }
  .wrapper, nav#nav-principal > ul, #headercontent, .accueil_diaporama_template.accueil_diaporama_full .bx-controls-direction, #footer #pub {
    width: $small-screen;
  }
  /* Menu */
  nav#nav-principal .navigation_button_p > ul,
  .accueil_diaporama_template.accueil_diaporama_full .slide-text {
    max-width: $small-screen;
  }
  /*Suppression du bloc de recherche à droite*/
  #block-flottant-search {
    display: none;
  }
  /* Met une position par défaut du logo et du slogan */
  .slogan {
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
    right: 0 !important;
    bottom: auto !important;
    margin: 0;
    padding: 0;
    text-align: center !important;
    font-size: 1.2rem !important;
    width: 100% !important;
    display: block;

    p, span {
      text-align: center !important;
      font-size: 15px !important;

    }
  }
  #logo {
    position: relative !important;
    width: 100% !important;
    text-align: center !important;
    left: 0 !important;
    top: 0 !important;
    padding: 0 0 15px;
    display: block;

    img {
      width: auto !important;
      max-height: 60px;

    }
  }
  /* Passage des deux colonnes en une */
  .sidebar {
    width: 100%;
    padding: 20px;

    li a::before {
      content: '- ';
      margin: 0 5px;
      display: inline-block;
    }
  }
  .sidebar, section.col-md-10 {
    padding: 0;
    margin-top: 0;
    width: 100%;
  }
  aside + section.content #bloc-fil-title {
    margin-left: calc((100% - 100vw) / 2);
  }
  .s8.single-article {
    #bloc-fil-title {
      margin-left: calc((100% - 100vw) / 2);
    }

    + .blog-sidebar {
      margin-top: 0;
    }
  }
  /* Bloc Fil Title */
  #bloc-fil-title {
    nav.arianne a {
      font-size: 12px;
    }

    h1 {
      font-size: 18px;
    }
  }
  /* Livre d'or */
  .template_livreor {
    &.template_livreor_2 {
      .message_lo {
        .blcFleche {
          top: -14px;
          right: 31px;
          left: auto;
        }

        .contenuMsgLO {
          border-width: 0 1px 1px 1px;

          &::before, &::after {
            height: 1px;
            width: auto;
            top: 0;
            bottom: auto;
          }

          &::before {
            right: 0;
            left: auto;
            width: 40px;
          }

          &::after {
            right: 66px;
            left: 0;
          }
        }
      }
    }
  }

  /* Fiche produit */
  .template_fiche_produit {
    &.template_fiche_produit_1 #fiche-produit {
      .wrap-images, .wrap-description {
        width: 100%;
      }

      .wrap-description {
        .bloc-quantite {
          margin-bottom: 15px;
        }

        .ajouter-panier {
          max-width: 100%;
          float: none;
          clear: left;

          > .addbasket {
            margin-left: 0;
          }
        }
      }
    }

    &.template_fiche_produit_2 #fiche-produit {
      .wrap-images {
        width: 100%;
        padding: 0;
      }

      #savoirplus {
        width: 100%;
        padding: 0;
      }

      .container-etiquette {
        width: auto;
      }
    }

    &.template_fiche_produit_3 #fiche-produit {
      .declinaisons-achat {
        width: 100%;
        float: none;
      }

      .details-descr-com {
        width: 100%;
        padding: 0;
      }
    }
  }
  /* Produits */
  aside.sidebar {
    display: none;

    + section.content {
      width: 100%;
    }
  }
  .template_boutique_all {
    .produits {
      .block-btn-addbasket {
        margin: 0;
      }

      .addbasket, .hors-stock {
        padding-top: calc(100% - #{$padding_produits});
        right: 0;
        margin: 0;
        left: auto;
      }

      .inside-addbasket, .inside-hors-stock {
        opacity: 1;
      }

      .inside-addbasket {
        bottom: 0;
        margin: 0;
        right: 5px;
        left: auto;
        width: 60px;
        height: 60px;
        line-height: 60px;

        i {
          width: 60px;
          height: 60px;
          line-height: 60px;
          bottom: 0;
          margin: 0;
          right: 0;
          left: auto;
          font-size: 20px;
        }

      }

      .inside-hors-stock {
        &, &::before {
          width: 60px !important;
          height: 60px !important;
          line-height: 60px !important;
          bottom: 0 !important;
          left: auto;
          right: 3px;
        }

        &::before {
          font-size: 20px;
        }
      }

      .productImageWrap {
        .zoom {
          display: none;
          width: 60px;
          height: 60px;
          line-height: 60px;
          bottom: 0;
          top: auto;
          opacity: 1;
          left: 0;
          right: auto;

          &::before {
            line-height: 60px;
            margin: 0;
            font-size: 20px;
          }
        }
      }
    }
  }
  .template_boutique_3 {
    .produits {
      .addbasket {
        padding-top: 0;
      }

      .productImageWrap {
        .zoom {
          display: none !important;
        }
      }

      .productContentWrap {
        .inside-addbasket {
          &, & i {
            width: auto !important;
            height: auto !important;
            line-height: normal !important;
            top: 0 !important;
            bottom: auto !important;
          }

          i {
            font-size: 16px !important;
            top: 8px !important;
            bottom: 8px !important;
            left: 10px;
            padding-right: 10px;
            right: auto;
          }
        }

        .inside-hors-stock {
          &, &::before {
            width: auto !important;
            height: auto !important;
            line-height: normal !important;
            top: 0 !important;
            bottom: auto !important;
          }

          &::before {
            font-size: 16px !important;
            top: 0 !important;
            bottom: 0 !important;
            left: 0;
            padding: 5px;
          }
        }
      }
    }
  }
  .template_boutique_5 {
    .produits {
      .productContentWrap {
        position: static;
      }

      .nomprod, .prix, .productImageWrap {
        width: 100%;
        float: none;
        clear: both;
        height: auto;
      }

      .addbasket, .hors-stock {
        padding-top: calc(100% - #{$padding_produits});
        right: 0;
        margin: 0;
      }
    }
  }
  .template_boutique_accueil {
    .produits {
      .mask {
        top: auto;
        bottom: 0;
        width: 60px;
        height: 60px;
        margin-top: -7px;
        left: 60px;
        display: block;
      }
    }

    &.accueil_boutique_template_1 {
      @include produitAccueilTaille(100px, 2, 30px);

      .produits {

      }
    }
  }

  /* Blog */
  .template_blog {
    &.template_blog_1 {
      .two-column-blog, .one-column-blog {
        flex-direction: column;
      }

      .blog-content, .blog-sidebar {
        &.s4, &.s8 {
          width: 100%;
          padding: 0;
        }
      }

      .blog-content {
        margin-bottom: 15px;

        .apercu-article {
          width: 100%;
          margin-left: 0;
          margin-right: 0;

          &-size {
            width: 100%;
          }

          &-gutter {
            width: 0;
          }
        }
      }
    }
  }

  /* Template panier */
  .template-panier {
    &.template-panier-2 {
      top: 45px;

      #header-panier {
        position: absolute;
        padding: 0;
        text-indent: 0;
        width: 45px;
        height: 45px;
        top: -45px;

        .fa {
          text-indent: 0;
          line-height: 45px;
          margin-right: 0;
        }

        .count-live {
          left: 25px;
          top: 20px;
        }
      }

      #panier #header-panier {
        color: transparent !important;
      }
    }

    &.template-panier-3 {
      position: relative;
      left: 0;
      top: 0;
      right: auto;
      bottom: auto;
      float: none;
      display: block;
      margin: 0 auto;
      padding: 15px 0 0;
      width: 100%;

      #paniercontent {
        width: $small-screen;
        min-width: 0;
        max-width: none;
      }

      #panier {
        text-align: left;
      }

      #header-panier {
        width: auto;
        display: inline-block;

        .paniertoggle {
          margin-left: 15px;
        }
      }

      .connexion p a {
        text-align: right;
      }
    }
  }

  /* Diaporama accueil */
  .accueil_diaporama_template {
    &.accueil_diaporama_template_4 {
      margin: 10px auto;

      .slide {
        li, li img {
          max-height: 280px;
          min-height: 280px;
        }
      }
    }
  }

}

@media #{$medium-and-up}{
  .menu_burger {
    display: none;
  }
}

@media #{$small-and-down} {
  .wrapper, #headercontent {
    width: 100%;
  }

  nav#nav-principal > ul, .accueil_diaporama_template.accueil_diaporama_full .slide-text, .accueil_diaporama_template.accueil_diaporama_full .bx-controls-direction, #footer #pub {
    width: 100%;
  }

  /* Produits */
  .template_boutique_all {
    .produits {
      .nomprod {
        width: 100%;
      }

      .prix {
        width: 100%;
      }
    }

    &.template_boutique_3 {
      .produits {
        .produits-bloc-image {
          border: none;

          img {
            padding: 0;
          }
        }
      }
    }

    &.template_boutique_5 {
      .produits {
        @include elemTailleCol(1, 0, true);

        .container-etiquette {
          padding-left: 5px;
        }
      }
    }

    &.template_boutique_accueil {
      &.accueil_boutique_template_1 {
        @include produitAccueilTaille(100px, 1, 0px);
      }
    }
  }

  /* Fiche produit */
  .template_fiche_produit {
    &.template_fiche_produit_2 #fiche-produit {
      .wrap-images {
        .sliderImageFicheProd {
          width: 100%;
        }

        #sliderImagePreviews {
          > a, > a:first-child {
            margin: 1%;
          }
        }
      }
    }
  }

  /* Album */
  .template_album {
    .galerie {
      .commentP {
        opacity: 1;

        &::before {
          display: none;
        }
      }

      .addcommentaires {
        opacity: 1;
      }

      img {
        filter: grayscale(0);
      }
    }
  }

  /* Diaporama */
  .accueil_diaporama_template {
    .slide-text {
      p {
        opacity: 1 !important;
        font-size: 22px;
      }

      .content-slide-text {
        > a.slider-see-more {
          opacity: 1 !important;
        }

        > a.addcommentaires {
          opacity: 1 !important;
        }
      }
    }

    &.accueil_diaporama_template_4 {
      margin: 10px auto;

      .slide {
        li, li img {
          max-height: 200px;
          min-height: 200px;
        }
      }
    }
  }

  /* Template Panier */
  .template-panier {
    &.template-panier-3 {
      max-width: none;

      #header-panier {
        text-align: left;
        padding-right: 30px;
      }

      .count-live {
        left: 20px;
        top: 20px;
        margin-left: 0;
      }

      #paniercontent {
        width: 90%;
      }
    }
  }

  /* Footer */
  #top_fleche {
    display: none !important;
  }

  /* Tableaux responsive */
  @import '../../design/helper/responsive/mobile/mobile';

  #order table.table > tbody > tr > td {
    margin: .5em 0;
    width: 100%;
    padding: 10px;

    &:not([data-th=""])::before {
      min-width: 45%;
    }
  }
  #order .infosclient td.text-center {
    text-align: center;
  }

  .template-panier-3 {
    z-index: 1052;
  }

  /* Menu responsive */

  $menu_burger_width: 60px;
  $largeur_menu_max: 450px;

  .cmonsite-panier-3 #main-conteneur header, header {
    position: static;
  }
  #main-conteneur {
    position: relative;

    &::after {
      content: '';
      display: block !important;
      position: absolute;
      width: 100%;
      opacity: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, .5);
      z-index: -1;
      height: 100%;
      transition: z-index 0s linear .2s, opacity .2s linear;
    }

    &.menuBurgerOpen {
      &::after {
        transition-delay: 0s;
        opacity: 1;
        z-index: 1;
      }
    }
  }

  header .menu.navigation_p {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: $largeur_menu_max + $menu_burger_width;
    height: 100%;
  }

  nav#nav-principal {
    padding: 0;
    width: calc(100% - #{$menu_burger_width});
    max-width: 450px;
    position: absolute;
    top: 0;
    left: calc(-100% + #{$menu_burger_width});
    transition: left .2s linear, box-shadow .2s linear;
    z-index: 1053;
    height: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0);

    &.nav_ouverte {
      left: 0;
      box-shadow: 0 0 10px rgba(0, 0, 0, .7);
    }

    > ul {
      padding: 0;
      top: 0 !important;
    }

    ul {
      width: 100% !important;
      display: none;
      top: 100%;
      max-width: 100% !important;
      visibility: visible !important;
      opacity: 1 !important;
      z-index: 1 !important;
      max-height: none !important;
      position: relative !important;
    }

    .sous_navigation_button_p {
      .dropDown.sous_navigation_link_p {
        height: 100% !important;
        line-height: initial;
      }

      .sous_navigation_link_p {
        margin: 0 !important;
        padding: 20px 0 !important;
      }
    }

    li {
      width: 100%;
      display: block;
      position: relative;
      max-width: 100% !important;
    }

    a {
      /*Font size + padding : il faut au moins que le bloc fasse 40px de haut, taille minimale pour un bouton au doigt*/
      font-size: 1rem;
      line-height: 1rem;
      padding: 14px 0;
      width: 100%;
      text-align: center;
    }

    &.nav_ouverte {
      > ul {
        display: block;
      }
    }

    .navigation_button_p {
      > ul {
        position: relative;
        transform: scaleY(1);
        display: none;
      }
    }

    .menu_burger {

      left: 100%;
      width: $menu_burger_width;
      height: $menu_burger_width + 15px;
      padding: 0;
      position: absolute;
      transition: height .2s linear, width .2s linear;

      $height: 20px;
      $width: 30px;
      $line-size: 3px;

      &_icons {
        position: absolute;
        top: 15px;
        left: 0;
        height: $menu_burger_width;
        width: $menu_burger_width;
        overflow: hidden;
        transition: box-shadow .2s linear;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0);
        cursor: pointer;
        text-align: center;
        z-index: 2;
      }

      &::before, &::after {
        display: none !important;
      }

      .menu {
        &__icon {
          position: absolute;
          width: $width;
          height: $height;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &__line {
          position: absolute;
          left: 0;
          height: $line-size;
          width: 100%;
          border-radius: $line-size;
          pointer-events: none;

          &--1 {
            top: 0;
          }

          &--2 {
            top: 0;
            bottom: 0;
            margin: auto;
          }

          &--3 {
            bottom: 0;
          }
        }

        &__line--4,
        &__line--5 {
          opacity: 0;
          top: ($height - $line-size) / 2;
          transition: opacity .2s ease-in-out,
          transform .2s ease-in-out;
        }

        &__line--1 {
          transition: width .2s ease-in-out .4s;
        }

        &__line--2 {
          transition: width .2s ease-in-out .3s;
        }

        &__line--3 {
          transition: width .2s ease-in-out .2s;
        }

        &__line--4 {
          transform: rotate(45deg) translate3d(-$width, 0, 0);
          transition: opacity .2s ease-in-out .2s,
          transform .2s ease-in-out .2s;
        }

        &__line--5 {
          transform: rotate(-45deg) translate3d($width, 0, 0);
          transition: opacity .2s ease-in-out,
          transform .2s ease-in-out;
        }
      }

      &.menu_deploye {
        height: 100%;
        width: 100%;

        .menu_burger_icons {
          box-shadow: 0 0 10px rgba(0, 0, 0, .7);
        }

        .menu {
          // Open
          &__line--1 {
            width: 0;
            transition: width .2s ease-in-out;
          }

          &__line--2 {
            width: 0;
            transition: width .2s ease-in-out .1s;
          }

          &__line--3 {
            width: 0;
            transition: width .2s ease-in-out .2s;
          }

          &__line--4 {
            opacity: 1;
            transform: rotate(45deg) translate3d(0, 0, 0);
            transition: opacity .2s ease-in-out .2s,
            transform .2s ease-in-out .2s;
          }

          &__line--5 {
            opacity: 1;
            transform: rotate(-45deg) translate3d(0, 0, 0);
            transition: opacity .2s ease-in-out .4s,
            transform .2s ease-in-out .4s;
          }
        }
      }

      /*&::before {
                content: '\f0c9';
                display: inline-block;
                margin-right: 5px;
                font-family: 'FontAwesome', sans-serif;
            }*/
    }
  }

  .dropDown {
    position: absolute;
    right: 0;
    top: 0;
    font-weight: bold;
    display: block;
    height: 45px;
    width: 60px;
    line-height: 45px;
    cursor: pointer;
    text-align: center;
    font-size: 1.2rem;

    &::before {
      font-family: 'FontAwesome', sans-serif;
      content: "\f107";
    }
  }
  .sousmenu_ouvert > .dropDown {
    &::before {
      content: "\f106";
    }
  }

  //Cache la barre latérale
  #minisite-sidebar {
    display: none;
  }
}